import LocalStore from './localStore'
import EXP from './localStore/explain'
/**
 * 数据共享模块，主要通过localStorage共享
 */
const localStore = new LocalStore('rx_');
/**
 * 需要附加的数据字段，适用于简单，无响应处理的数据内容
 * 普通单个字段数据可以定义在_localData数组中，并通过（A.db.字段名）修改数据
 * 使用localStore.defineObject创建的数据对象是深度响应的，对象内的任何属性值更新，本地的数据也将更新。
 */
const _localData = ['token', 'levelOne', 'baseInfo','udeviceInfo']
// 数据对象
const db = {
    // 清除数据，可以是数据字段的字符串，也可以是字符串数组；当值是true时表示清除所有；
    clear(name) {
        localStore.remove(name)
    },
    logout() {
        localStore.remove(['token', 'user', 'expertInfo', 'baseInfo']);
    },
    get user() {
        return localStore.defineObject('user', EXP);
    },
    set user(value) {
        localStore.defineObject('user', value);
    },
    get expertInfo() {
        return localStore.defineObject('expertInfo', EXP);
    },
    set expertInfo(value) {
        localStore.defineObject('expertInfo', value);
    }
}
// 扩展存储字段
localStore.defineProperties(db, _localData)
// 添加到A对象上
if (window.A) {
    A.assign({
        db
    })
}

export default db;